import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';

import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { Banner } from '../components/Banner';
import SmileSvg from 'assets/smile.svg';
import RocketShipSvg from 'assets/rocket-ship.svg';
import CollectionSvg from 'assets/collection.svg';
import GlobeSvg from 'assets/globe.svg';
import HealthSvg from 'assets/Medical-care-pana.svg';
import HousingSvg from 'assets/Apartment-rent-bro.svg';
import EducationSvg from 'assets/Teaching-cuate.svg';
import TourismSvg from 'assets/freepik-Bear-inject.svg';
import { ContributionsAndYoutube } from '../components/ContributionsAndYoutube';

export const Landing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      <Banner />
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="bg-[#FBCA16] p-14">
          <div className="text-2xl font-bold mb-4 paytone-one-bold">
            IT IS TIME TO EXERCISE OUR PATRIOTISM
          </div>
          <div className="mb-2">
            As Africans, and particularly as Ugandans the time has come to start solving our own
            problems as opposed to always holding out a begging hand. Nelson Mandela said, "Poverty
            is not an accident. Like slavery and apartheid, it is man-made and can be removed by the
            actions of human beings".
          </div>
          <div className="mb-2">
            “We cannot live only for ourselves. A thousand fibers connect us with our fellow
            men,” Herman Melville.
          </div>
          <div className="mb-2">
            Imagine if in the words of Herman Melville, we lived as though we were connected with
            our fellow man, if we cared a little bit more about the wellbeing of our brothers and
            sisters in society, if you and I were our brother's keepers, what a wonderful Uganda we
            would all have!
          </div>
        </div>
        <div className="bg-[#ED1D24] text-white p-14">
          <div className="text-2xl font-bold mb-4 paytone-one-bold">
            BE A PART OF THIS REVOLUTION
          </div>
          <div className="mb-2">
            WHY THE 10,000 PROJECT: We are mobilizing a minimum of 1million Ugandans, each
            contributing 10,000/= per month. Do the Math: that is 10 Billion Shillings per month and
            120 Billion Shillings per year!
          </div>
          <div className="">
            We are investing this money in our own economy and solving problems of unemployment,
            health care, low incomes, bringing back value to our money, improving the standards of
            living, among others.
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 my-8">
        <div className="flex flex-col items-center justify-center">
          <div className="">
            <img src={SmileSvg} alt="smile" className="h-12" />
          </div>
          <div className="text-3xl font-bold paytone-one-bold">1,858</div>
          <div className="text-sm">MEMBERS</div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="">
            <img src={RocketShipSvg} alt="smile" className="h-12" />
          </div>
          <div className="text-3xl font-bold paytone-one-bold">1Million</div>
          <div className="text-sm">MIN TARGET MEMBERS / YEAR</div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="">
            <img src={CollectionSvg} alt="smile" className="h-12" />
          </div>
          <div className="text-3xl font-bold paytone-one-bold">406Million</div>
          <div className="text-sm">COLLECTIONS</div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="">
            <img src={GlobeSvg} alt="smile" className="h-12" />
          </div>
          <div className="text-3xl font-bold paytone-one-bold">120Billion</div>
          <div className="text-sm">MIN. TARGET COLLECTIONS / YEAR</div>
        </div>
      </div>
      <div className="bg-[#FBCA16] p-12">
        <div className="text-center text-3xl font-bold mb-4">
          The different <span className="text-[#ED1D24]"> Sectors we intend to Invest in.</span>
        </div>
        <div className="md:mx-24 grid grid-cols-1 md:grid-cols-2 items-center">
          <div className="">
            <img src={HealthSvg} alt="smile" />
          </div>
          <div className="">
            <div className="text-2xl paytone-one-bold mb-2">HEALTH</div>
            <div className="">
              Lorem ipsum dolor sit amet consectetur. Euismod quam sem molestie convallis ut
              parturient. Tortor venenatis erat ac arcu viverra. Erat sed aliquam sed rhoncus leo
              sem sit egestas. Tortor dictum integer a commodo amet etiam. Malesuada condimentum
              nibh et semper viverra vestibulum elit tempor scelerisque. Ipsum nec euismod bibendum
              mauris nulla lorem. Faucibus pellentesque mauris pulvinar euismod pharetra. Non ipsum
              sit turpis ipsum. Viverra in lectus nunc vel pellentesque. Egestas maecenas laoreet
              nisl sed lectus nunc gravida enim. Lacus est nulla felis augue id elementum orci nisi.
              Viverra.
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#DE3C3E] p-12">
        <div className="md:mx-24 grid grid-cols-1 md:grid-cols-2 items-center">
          <div className="">
            <div className="text-2xl paytone-one-bold mb-2 text-white">HOUSING</div>
            <div className="text-white">
              Lorem ipsum dolor sit amet consectetur. Euismod quam sem molestie convallis ut
              parturient. Tortor venenatis erat ac arcu viverra. Erat sed aliquam sed rhoncus leo
              sem sit egestas. Tortor dictum integer a commodo amet etiam. Malesuada condimentum
              nibh et semper viverra vestibulum elit tempor scelerisque. Ipsum nec euismod bibendum
              mauris nulla lorem. Faucibus pellentesque mauris pulvinar euismod pharetra. Non ipsum
              sit turpis ipsum. Viverra in lectus nunc vel pellentesque. Egestas maecenas laoreet
              nisl sed lectus nunc gravida enim. Lacus est nulla felis augue id elementum orci nisi.
              Viverra.
            </div>
          </div>
          <div className="">
            <img src={HousingSvg} alt="smile" />
          </div>
        </div>
      </div>

      <div className="bg-[#FBCA16] p-12">
        <div className="md:mx-24 grid grid-cols-1 md:grid-cols-2 items-center">
          <div className="">
            <img src={EducationSvg} alt="smile" />
          </div>
          <div className="">
            <div className="text-2xl paytone-one-bold mb-2">EDUCATION</div>
            <div className="">
              Lorem ipsum dolor sit amet consectetur. Euismod quam sem molestie convallis ut
              parturient. Tortor venenatis erat ac arcu viverra. Erat sed aliquam sed rhoncus leo
              sem sit egestas. Tortor dictum integer a commodo amet etiam. Malesuada condimentum
              nibh et semper viverra vestibulum elit tempor scelerisque. Ipsum nec euismod bibendum
              mauris nulla lorem. Faucibus pellentesque mauris pulvinar euismod pharetra. Non ipsum
              sit turpis ipsum. Viverra in lectus nunc vel pellentesque. Egestas maecenas laoreet
              nisl sed lectus nunc gravida enim. Lacus est nulla felis augue id elementum orci nisi.
              Viverra.
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#DE3C3E] p-12">
        <div className="md:mx-24 grid grid-cols-1 md:grid-cols-2 items-center">
          <div className="">
            <div className="text-2xl paytone-one-bold mb-2 text-white">TOURISM</div>
            <div className="text-white">
              Lorem ipsum dolor sit amet consectetur. Euismod quam sem molestie convallis ut
              parturient. Tortor venenatis erat ac arcu viverra. Erat sed aliquam sed rhoncus leo
              sem sit egestas. Tortor dictum integer a commodo amet etiam. Malesuada condimentum
              nibh et semper viverra vestibulum elit tempor scelerisque. Ipsum nec euismod bibendum
              mauris nulla lorem. Faucibus pellentesque mauris pulvinar euismod pharetra. Non ipsum
              sit turpis ipsum. Viverra in lectus nunc vel pellentesque. Egestas maecenas laoreet
              nisl sed lectus nunc gravida enim. Lacus est nulla felis augue id elementum orci nisi.
              Viverra.
            </div>
          </div>
          <div className="">
            <img src={TourismSvg} alt="smile" />
          </div>
        </div>
      </div>

      <ContributionsAndYoutube />
    </WebLayout>
  );
};
