import { Button, Spinner } from 'components/Elements';
import { Notifications } from 'components/Notifications/Notifications';
import { queryClient } from 'lib/react-query';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { motion } from 'framer-motion';
import Error404Svg from 'assets/404.svg';

const ErrorFallback = () => {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center" role="alert">
      <img src={Error404Svg} alt="" className="h-48" />
      <h2 className="text-2xl font-semibold text-red-600 mb-2">Development Error.</h2>
      <p className="text-gray-600 mb-6">
        We’re sorry for the inconvenience. Seems like the page you're looking for is either still
        being developed or doesn't exist.
      </p>
      <button
        onClick={() => {
          localStorage.clear();
          window.location.assign(window.location.origin);
        }}
        className="px-6 py-2 bg-[#D92425] text-white rounded-lg shadow-md transition-colors"
      >
        Back Home
      </button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <>
              <Router>
                <motion.div
                  initial={{ opacity: 0, y: 20 }} // Initial state: hidden
                  animate={{ opacity: 1, y: 0 }} // Final state: fully visible
                  exit={{ opacity: 0, y: -20 }} // Exit state for when component unmounts
                  transition={{ duration: 0.3 }} // Transition duration
                >
                  {children}
                </motion.div>
              </Router>
            </>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
