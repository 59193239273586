import React, { useEffect } from 'react';
import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes';

import 'sweetalert2/src/sweetalert2.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
