import { useState, useEffect } from 'react';
import { Mail, Phone, Menu, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { Logo } from '..';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="border bg-white shadow-md">
      {/* Top Navbar Section */}
      <div className="bg-black text-white flex flex-col sm:flex-row justify-between items-center p-3 px-8">
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0">
          <div className="flex items-center space-x-2">
            <Phone />
            <span className="text-sm">0393-225-462</span>
          </div>
          <div className="flex items-center space-x-2 sm:mx-8">
            <Mail />
            <span className="text-sm">comms@withloveuganda.org</span>
          </div>
          <div className="hidden sm:flex items-center space-x-2">
            <span className="text-sm">FAQs</span>
            <span className="mx-2">|</span>
            <span className="text-sm">10K Project</span>
          </div>
        </div>
        <div className="flex justify-center sm:justify-end items-center mt-2 sm:mt-0">
          <a
            href="https://portal.withloveuganda.org"
            target="_blank"
            className="font-bold"
            rel="noopener noreferrer"
          >
            Login
          </a>
        </div>
      </div>

      {/* Main Navigation Section */}
      <div className="flex items-center justify-between px-4 sm:px-8 md:px-16 lg:px-24 py-3">
        <Link to="/" className="text-lg font-bold">
          <Logo />
        </Link>
        <button className="sm:hidden text-gray-800 focus:outline-none" onClick={toggleMenu}>
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Navigation Links (Hidden on Small Screens) */}
        <div
          className={`flex-col sm:flex sm:flex-row sm:items-center ${
            isOpen ? 'flex' : 'hidden'
          } space-y-4 sm:space-y-0 sm:space-x-6 text-lg sm:mt-0 mt-3`}
        >
          <Link
            className="hover:text-gray-800 hover:font-semibold"
            to="/"
            onClick={() => setIsOpen(false)}
          >
            Home
          </Link>
          <Link
            className="hover:text-gray-800 hover:font-semibold"
            to="/about-us"
            onClick={() => setIsOpen(false)}
          >
            About Us
          </Link>
          <Link
            className="hover:text-gray-800 hover:font-semibold"
            to="/director"
            onClick={() => setIsOpen(false)}
          >
            Directors
          </Link>
          <Link
            className="hover:text-gray-800 hover:font-semibold"
            to="/contributions"
            onClick={() => setIsOpen(false)}
          >
            Contributions
          </Link>
          <Link
            className="hover:text-gray-800 hover:font-semibold"
            to="/satellite-city"
            onClick={() => setIsOpen(false)}
          >
            Satellite City
          </Link>
          <Link
            className="hover:text-gray-800 hover:font-semibold"
            to="/contact-us"
            onClick={() => setIsOpen(false)}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </nav>
  );
};
