import React from 'react';

export const ContributionsAndYoutube = () => {
  return (
    <>
      <div className="md:mx-24 grid grid-cols-1 md:grid-cols-2 gap-8 p-14">
        <div className="">
          <div className="text-2xl paytone-one-bold">
            <span className="text-[#DE3C3E]">MONTHLY CONTRIBUTIONS</span> SINCE 2018
          </div>
          <div className="mb-8">
            Showing current years monthly progress and you can monitor it from here.
          </div>
          <div className="text-2xl paytone-one-bold">MAY 2018</div>
          <div className="">
            Lorem ipsum dolor sit amet consectetur. Euismod quam sem molestie convallis ut
            parturient. Tortor venenatis erat ac arcu viverra. Erat sed aliquam sed rhoncus leo sem
            sit egestas. Tortor dictum integer a commodo amet etiam. Malesuada condimentum nibh et
            semper viverra vestibulum elit tempor scelerisque.
          </div>
          <div className="font-bold mb-4">Greatness is Wherever Someone is trying to find it.</div>
          <div className="">Raised</div>
          <div className="font-bold mb-4">UGX 30K</div>
          <button
            style={{
              backgroundColor: '#D82025',
              color: 'white',

              border: 'none',
              cursor: 'pointer',
            }}
            className="rounded-full text-lg px-8 py-2"
          >
            View More Contributions
          </button>
        </div>
        <div className="">
          <div className="text-2xl paytone-one-regular">VISIT OUR YOUTUBE CHANNEL</div>
          <div className="">
            We provide video content to help you get started with With Love Uganda.
          </div>
          <div className="mt-4">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/miqDgMS_8QA"
              title="Know More About WLU"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
