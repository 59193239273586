import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import Slider from 'react-slick';
import Slide1 from 'assets/slide1.webp';
import Slide2 from 'assets/slide3.webp';
import Slide3 from 'assets/slide4.webp';

export const Banner = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const carouselItems = [
    {
      title: 'Our First Meeting',
      subtitle: 'We Started With Momentum',
      imageUrl: Slide1,
      buttonText: 'Explore Now',
      buttonLink: '/explore',
    },
    {
      title: 'Bring Your Vision to Life',
      subtitle: 'Customizable designs tailored to your needs',
      imageUrl: Slide2,
      buttonText: 'Get Started',
      buttonLink: '/get-started',
    },
    {
      title: 'High-Quality Designs',
      subtitle: 'Crafted by professional designers',
      imageUrl: Slide3,
      buttonText: 'Learn More',
      buttonLink: '/about',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="">
      <Slider {...settings}>
        {carouselItems.map((item, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <div
              style={{
                backgroundImage: `url(${item.imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '70vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  maxWidth: '600px',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  padding: '20px',
                  borderRadius: '8px',
                }}
              >
                <h2 className="paytone-one-bold text-4xl md:text-6xl">{item.title}</h2>
                <p className="text-xl mt-2 mb-4">{item.subtitle}</p>
                <button
                  onClick={() => navigate(item.buttonLink)}
                  style={{
                    backgroundColor: '#D82025',
                    color: 'white',

                    border: 'none',
                    cursor: 'pointer',
                  }}
                  className="rounded-full text-lg px-8 py-2"
                >
                  {item.buttonText}
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
