import React from 'react';
import { Facebook, Instagram, LifeBuoy, Linkedin, Smartphone, Twitter } from 'react-feather';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="">
      <div className="grid grid-cols-1 md:grid-cols-3 px-0 md:px-24 md:-mb-10">
        <div className="bg-black p-8">
          <div className="">
            <LifeBuoy className="text-white mb-4" size={48} />
          </div>
          <div className="text-lg font-bold text-white mb-2">Prompt Support</div>
          <div className="text-xs text-white">Need help? Please don’t hesitate to contact us.</div>
        </div>
        <div className="bg-[#FBCA16] p-8">
          <div className="">
            <Smartphone className="text-white mb-4" size={48} />
          </div>
          <div className="text-lg font-bold text-white mb-2">Use MTN Mobile Money</div>
          <div className="text-xs text-white">
            And join by following the MTN Mobile Money steps outlined above to send your
            contributions
          </div>
        </div>
        <div className="bg-[#D82025] p-8">
          <div className="">
            <Smartphone className="text-white mb-4" size={48} />
          </div>
          <div className="text-lg font-bold text-white mb-2">Use Airtel Mobile Money</div>
          <div className="text-xs text-white">
            And join by following the Airtel Mobile Money steps outlined above to send your
            contributions
          </div>
        </div>
      </div>
      <div className="bg-[#252525] p-4 md:p-24 text-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-32">
          <div className=" text-white">
            <div className="flex flex-col">
              <div className="font-bold">The 10,000 Project</div>
              <div className="h-2 w-24 bg-red-500 my-2"></div>
              <div className="text-sm">
                As of 2018, Uganda is estimated to be having a population of about 44 million, of
                which 15 million of these are living below the poverty line. And those slightly
                above, which is the biggest number, are also struggling through life literally
                living off Hand to Mouth not being able to save a dime. Therefore, WITH LOVE UGANDA,
                is mobilizing a minimum of 1 Million Ugandans each Contributing 10,000/= per month.
                Imagine the change we would create and re write history this JUST 10,000/=. Like
                Coretta Scott King put it, ‘’the greatness of a community is most accurately
                measured by the compassionate actions of its members.”
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="font-bold">Quick Contact</div>
            <div className="h-2 w-24 bg-red-500 my-2"></div>
            <div className="text-sm">+(256) 393 225462</div>
            <div className="h-[1px] w-48 bg-white my-4"></div>
            <div className="text-sm">+(256) 772 437173</div>
            <div className="h-[1px] w-48 bg-white my-4"></div>
            <div className="mb-4 text-sm">
              Plot 2269, Sserwadda Close Bukoto, P.O Box 138642 Kampala-Uganda.
            </div>
            <div className="flex flex-row items-center space-x-2">
              <Facebook />
              <Twitter />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black text-white p-8 px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-xs">&copy; 2024 WLU. All Rights Reserved</p>
          <div className="flex flex-row items-end space-x-2 text-sm">
            <Link>FAQs</Link>
            <div>|</div>
            <Link>Contact Us</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
