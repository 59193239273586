import {
  AboutUs,
  Careers,
  ContactUs,
  CookieNotice,
  Landing,
  TermsAndConditions,
} from 'features/misc';
import { useRoutes } from 'react-router-dom';
// import { lazyImport } from 'utils/lazyImport';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  // const auth = useAuth();

  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/about-us', element: <AboutUs /> },
    { path: '/contact-us', element: <ContactUs /> },
    { path: '/careers', element: <Careers /> },
    { path: '/cookie-notice', element: <CookieNotice /> },
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },

    // { path: '/projects', element: <Projects /> },
  ];

  const routes = publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
